export default {
  methods: {
    copy(string) {
      //Creating textarea element
      let textarea = document.createElement("textarea")
      //Settings its value to the thing you want to copy
      textarea.value = string
      //Appending the textarea to body
      document.body.appendChild(textarea)
      //Selecting its content
      textarea.focus()
      textarea.select()
      //Copying the selected content to clipboard
      document.execCommand("copy")
      //Removing the textarea
      document.body.removeChild(textarea)

      alert(`${string} ${this.$root.$t("TABLE.COPY_CLIPBOARD")}`);
    },
    translateErrorFromCode(res) {
      try {
        const responseData = res.response.data
        if (responseData) {
          switch (responseData.status) {
            case 401:
              return this.$t("ERROR.USER_PASS_INCORRECT")
            case 410:
              return this.$t("ERROR.USERNAME_NOT_FOUND")
            case 411:
              return this.$t("ERROR.SERVER_ERROR")
            case 412:
              return this.$t("ERROR.DATA_NOT_FOUND")
            case 413:
              return this.$t("ERROR.INSUFFICENT_BALANCE")
            case 414:
              return this.$t("ERROR.CLIENT_NOT_FOUND")
            case 415:
              return this.$t("ERROR.USERNAME_EXIST")
            default:
              return responseData.message
          }
        } else {
          return res.message
        }
      } catch (error) {
        return res.message
      }
    }
  }
};
